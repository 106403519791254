import * as React from "react";
import Layout from "../components/layout";
import { useIntl } from "gatsby-plugin-react-intl";
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Button } from 'semantic-ui-react';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';

const ContactUs = (): JSX.Element => {
  const m = useIntl().messages;
  const intl = useIntl();
  const {placeholderImage} = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "APS-background.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }`);
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{m.contacts}</title>
      </Helmet>
      <BackgroundImage Tag="div" {...bgImage}>
        <div className={"ui one column grid " + (intl.locale === "en" ? "text-ltr" : "text-rtl")} style={{
          height: '200px',
          margin: '2rem .5rem',
          color: '#ffffff',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <div className="column">
            <h1>{m.message_contact_get_in_touch}</h1>
            <div className={"ui unordered inverted relaxed list"}>
              <div className="item">
                <i className="home icon"/>
                <span className="content">{m.aps_address}</span>
              </div>
              <div className="item">
                <i className="phone icon"/>
                <div className="content" dir="ltr">+93(0)&nbsp;20&nbsp;2210622</div>
              </div>
              <div className="item">
                <i className="mail icon"/>
                <div className="content">info@aps.gov.af</div>
              </div>
            </div>

            <Button href="https://forms.gle/eA3PGg2BwKXgeQtf7" target={"_blank"} size="large">{m.message_contact_get_in_touch}</Button>
          </div>
        </div>
      </BackgroundImage>
      <div className="ui raised fitted segment">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3731.847301152106!2d69.16702138848089!3d34.539290300850126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2cebdf85a55c6f84!2sAfghanistan+Payments+Systems!5e0!3m2!1sen!2s!4v1485931013731"
          width="100%" height="400px" frameBorder="0">
        </iframe>
      </div>
    </Layout>
  );
};

export default ContactUs;